import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscriber } from 'rxjs';
import { SnackBarService } from 'src/app/shared/services/snack-bar/snack-bar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-emr-completion',
  templateUrl: './emr-completion.component.html',
  styleUrls: ['./emr-completion.component.scss']
})
export class EmrCompletionComponent implements OnDestroy, AfterViewInit {
  isApiCallInProgress: boolean = false
  isMailIdInValid: boolean = true
  private _sub = new Subscriber()
  @ViewChild('homeLink') homeLink!: ElementRef<HTMLAnchorElement>
  timeTakenToFillEmr!: string
  isLessThanFiveSec!: boolean
  anyApiFailed: boolean = false;
  isMockEmr: boolean | null = null
  constructor(
    // private _gynaeClient: GynaeClientService,
    private _http: HttpClient,
    private _route: ActivatedRoute,
    private _snackBar: SnackBarService,
    private _router: Router
  ) {
    this._sub.add(
      this._route.queryParams.subscribe(query => {
        const { timeTaken, isLessThanFiveSec, isApiFailed } = query
        if (timeTaken) {
          this.timeTakenToFillEmr = timeTaken
        }
        if (isApiFailed) {
          this.anyApiFailed = isApiFailed === 'true'
        }
        this.isLessThanFiveSec = isLessThanFiveSec == 'true'
      })
    )
  }
  ngAfterViewInit(): void {
    this._confettiPlay()
    if (localStorage.getItem('isTraining') === 'true') {
      this.isMockEmr = true
    } else {
      this.isMockEmr = false
    }
  }

  url = `https://pristyndocapp.page.link/XktS?apptId=${localStorage.getItem('appointmentId')}`

  emailChangeHandler(val: string) {
    const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/
    this.isMailIdInValid = !regex.test(val)
  }

  sendEmail(email: string) {
    email = email.trim().toLowerCase()
    if (!email) return
    this.isApiCallInProgress = true
    this._sub.add(
      this._sendEmailToPatients(email).subscribe(
        res => {
          this.isApiCallInProgress = false
          if (res.status === 'Success') {
            setTimeout(() => {
              this.homeLink.nativeElement.click()
            }, 1000);
          }
          this._snackBar.default(res.description)
        },
        (err) => {
          this.isApiCallInProgress = false
        }
      )
    )
  }


  private _sendEmailToPatients(email: string) {
    const url = environment.emrUrl + 'sendMail'
    const reqBody = {
      emailIdOfPatient: email,
      docCategory: localStorage.getItem('category-name'),
      doctorId: localStorage.getItem('docId'),
      appointmentId: localStorage.getItem('appointmentId'),
      leadId: localStorage.getItem('leadId'),
      patientName: localStorage.getItem('patientName')
    }
    return this._http.post<any>(url, reqBody)
  }


  private _confettiPlay() {
    const element: any = document.getElementById('e0DQ82qcIov1');
    if (element) {
      element.svgatorPlayer.ready(function () {
        const player = element ? element.svgatorPlayer : {};
        if (player.play) {
          player.play();
        }
      });
    }
  }

  moveToTraining() {
    this._router.navigateByUrl('training')
  }
  ngOnDestroy(): void {
    this._sub.unsubscribe()
  }

}
