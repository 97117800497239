import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(
    private _bar:MatSnackBar
  ) { }

  private matSnackBarConfig: MatSnackBarConfig = {
    duration: 3000,
    horizontalPosition: 'center',
    verticalPosition: "top"
  }

  private matSnackBarTrainingConfig: MatSnackBarConfig = {
    duration: 3000,
    horizontalPosition: 'center',
    verticalPosition: "bottom"
  }

  success(message: string, action: any = null) {
    this._bar.open(message, action, {
      ...this.matSnackBarConfig,
      panelClass: 'snackbar-success'
    })
  }

  error(message: string, action: any = null) {
    this._bar.open(message, action, {
      ...this.matSnackBarConfig,
      panelClass: 'snackbar-error'
    })
  }

  info(message: string, action: any = null) {
    this._bar.open(message, action, {
      ...this.matSnackBarConfig,
      panelClass: 'snackbar-info'
    })
  }

  warning(message: string, action: any = null) {
    this._bar.open(message, action, {
      ...this.matSnackBarConfig,
      panelClass: 'snackbar-warning'
    })
  }
  default(message: string, action: any = null) {
    this._bar.open(message, action, {
      ...this.matSnackBarConfig,
      panelClass: 'snackbar-default'
    })
  }

  trainingWarning(message: string, action: any = null) {
    this._bar.open(message, action, {
      ...this.matSnackBarTrainingConfig,
      panelClass: 'snackbar-training-warning'
    })
  }
}
