import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const accessToken = localStorage.getItem('access_token');
    if (request.url.match(/token/gi)) {
      const refreshTokenRequest = request.clone({
        setHeaders: {
          Authorization: "Basic " + btoa("android-client:android-secret"),
          "Content-Type": "application/x-www-form-urlencoded",
        }
      });
      return next.handle(refreshTokenRequest);
    }
    if (accessToken) {
      const updateRequest = request.clone({
        setHeaders: {
          'Authorization': 'Bearer ' + accessToken
        }
      });
      return next.handle(updateRequest);
    }
    return next.handle(request);
  }
}
